@import "@/src/styles/common.scss";
@import "../common.module.scss";
.link-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $main;
    color: var(--color);
    align-items: center;
}
.link-icon {
    font-size: inherit;
    margin-left: 0.08rem;
}
