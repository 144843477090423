@import "@/src/styles/common.scss";
.unpaid-container {
    line-height: 0.72rem;
    background: rgba(255, 120, 38, 0.6);
    color: #fff;
    display: flex;
    justify-content: center;
    .check-btn {
        text-decoration: underline;
        cursor: pointer;
        color: #fff;
    }
}
